import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import './timeline.css';

export const historyFormatImage = graphql`
  fragment historyFormatImage on File {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
`;

const Timeline = React.memo(() => {
  const images = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "history/0_0_1_1835_8_web.png" }) {
        ...historyFormatImage
      }
      two: file(relativePath: { eq: "history/0_0_2_1936_3_web.jpg" }) {
        ...historyFormatImage
      }
      three: file(relativePath: { eq: "history/0_0_3_1955_2_2_web.jpg" }) {
        ...historyFormatImage
      }
      four: file(relativePath: { eq: "history/0_0_4_1962_2_web.png" }) {
        ...historyFormatImage
      }
      five: file(relativePath: { eq: "history/0_0_5_1963_2_web.png" }) {
        ...historyFormatImage
      }
      six: file(relativePath: { eq: "history/0_0_6_1979_3_web.png" }) {
        ...historyFormatImage
      }
      seven: file(relativePath: { eq: "history/0_0_7_1981_4_web.png" }) {
        ...historyFormatImage
      }
      eight: file(relativePath: { eq: "history/0_0_8_1986_3_web.jpg" }) {
        ...historyFormatImage
      }
      nine: file(relativePath: { eq: "history/0_0_9_1987_2_web.jpg" }) {
        ...historyFormatImage
      }
      ten: file(relativePath: { eq: "history/0_1_1_1987_2_web.jpg" }) {
        ...historyFormatImage
      }
      eleven: file(relativePath: { eq: "history/0_1_2_1989_3_web.png" }) {
        ...historyFormatImage
      }
      twelve: file(relativePath: { eq: "history/0_1_3_1991_2_web.jpg" }) {
        ...historyFormatImage
      }
      threeten: file(relativePath: { eq: "history/0_2_1_2000_2_web.jpg" }) {
        ...historyFormatImage
      }
      fourten: file(relativePath: { eq: "history/0_2_6_2005_2_web.jpg" }) {
        ...historyFormatImage
      }
      fiveten: file(relativePath: { eq: "history/0_2_4_2014_2_web.jpg" }) {
        ...historyFormatImage
      }
      sixten: file(relativePath: { eq: "history/0_1_6_2007_2_3_web.png" }) {
        ...historyFormatImage
      }
      seventen: file(relativePath: { eq: "history/Aloft_6698-l1.jpg" }) {
        ...historyFormatImage
      }
      eigthten: file(relativePath: { eq: "history/StoSignature-Pic-Collage.png" }) {
        ...historyFormatImage
      }
    }
  `);
  return (
    <VerticalTimeline>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.one.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">1835</h3>
        <div className="timelineelement-separator"></div>
        <p>
          A mediados de la rápida expansión industrial del siglo XIX, se estableció una fábrica de
          cemento y cal en Weizen, Alemania.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.two.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">1836</h3>
        <div className="timelineelement-separator"></div>
        <p>
          Wilhelm Stotmeister, propietario de una planta de cal en Klergen, amplía su operación al
          adquirir la planta de Weizen.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.three.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">1955</h3>
        <div className="timelineelement-separator"></div>
        <p>
          Stotmeister es pionera e introduce revolucionarios revestimientos de paredes de resina
          sintética de alta calidad en el mercado europeo.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.four.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">1962</h3>
        <div className="timelineelement-separator"></div>
        <p>Fundación de Stotmeister & Co. Farben-und Baustoff-KG: nace la marca Sto.</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.five.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">1963</h3>
        <div className="timelineelement-separator"></div>
        <p>
          Sto presenta un nuevo e innovador sistema de paredes: <br /> un revestimiento que crea una
          capa ininterrumpida de aislamiento en el exterior del edificio e integra un revestimiento
          de resina a base de acrílico que es extremadamente duradero y duradero. <br /> Es el
          primer sistema de aislamiento exterior y acabado.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.six.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">1979</h3>
        <div className="timelineelement-separator"></div>
        <p>
          Sto se expande a los Estados Unidos para comercializar sistemas de acabado y aislamiento
          exterior de alta calidad y eficiencia energética. Sto Energy Conservation, la primera
          compañía estadounidense de Sto, se funda en Rutland, Vermont.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.seven.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">1981</h3>
        <div className="timelineelement-separator"></div>
        <p>
          Sto ayuda a formar la EIMA, la Asociación de Miembros de la Industria EIFS, para ayudar a
          establecer estándares para toda la industria
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.eight.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">1986</h3>
        <div className="timelineelement-separator"></div>
        <p>
          La expansión norteamericana continúa. Se abre una sucursal en California. Sto adquiere
          PowerwallCorp., Una compañía de Texas que luego pasó a llamarse División de Restauración
          de Concreto Sto.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.nine.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">1987</h3>
        <div className="timelineelement-separator"></div>
        <p>Sto Industries Canada Inc. se estableció el 21 de septiembre de 1987 en Toronto.</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.ten.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">1987</h3>
        <div className="timelineelement-separator"></div>
        <p>
          Las ventas nacionales de productos alcanzan niveles sin precedentes con la ayuda del
          Centro de Investigación Sto. Sto traslada la sede y construye una instalación de
          fabricación importante en Atlanta, Georgia, para manejar eficientemente el volumen cada
          vez mayor y centralizar las operaciones.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.eleven.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">1989</h3>
        <div className="timelineelement-separator"></div>
        <p>
          El rápido crecimiento de las líneas de productos y plantas requiere la formación de Sto
          Corp., el centro unificador de una Corporación Internacional.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.twelve.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">1991</h3>
        <div className="timelineelement-separator"></div>
        <p>
          Sto abre una planta en Phoenix, Arizona, que brinda servicios eficientes al oeste de los
          Estados Unidos.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.threeten.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">2000</h3>
        <div className="timelineelement-separator"></div>
        <p>
          Sto Corp. lanza StoGuard ® , la primera barrera contra la humedad y el aire de aplicación
          líquida para su uso sobre sustratos de madera.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.fourten.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">2005</h3>
        <div className="timelineelement-separator"></div>
        <p>
          Lanzamientos de Sto StoColor ® Lotusan ® , un nuevo recubrimiento revolucionaria con
          Lotus-Effect ® Technology, las propiedades de autolimpieza patentados.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.fiveten.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">2014</h3>
        <div className="timelineelement-separator"></div>
        <p>
          Sto Corp. se expande internacionalmente, adquiriendo ubicaciones en Chile,Colombia y
          Brasil.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.sixten.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">2015</h3>
        <div className="timelineelement-separator"></div>
        <p>
          Sto cambia el nombre de la línea de productos EIFS a StoTherm ® ci, lo que significa las
          propiedades térmicas y energéticamente eficientes de los sistemas de revestimiento EIFS.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.seventen.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">2018</h3>
        <div className="timelineelement-separator"></div>
        <p>
          StoPanel Technology se asocia con Dri-Design y Swiss Pearl para agregar opciones de
          paneles de metal y fibrocemento a las muchas opciones estéticas que ya se ofrecen con los
          paneles de pared prefabricados StoPanel.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement dateStyle={{ display: 'none' }}>
        <GatsbyImage
          image={images.eigthten.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="history-image"
        />
        <h3 className="vertical-timeline-element-title">2019</h3>
        <div className="timelineelement-separator"></div>
        <p>
          Sto Corp. lanza StoSignature ™, una mezcla de color, textura, perfiles y efectos que
          utiliza productos Sto para crear superficies terminadas personalizables para exteriores de
          edificios. <br /> <br /> Sto Corp. presenta el programa de recubrimientos Intelligent
          Technology + Design (iQ) ™ (Sto iQ Technology ™). El programa ofrece una familia de
          recubrimientos de alto rendimiento que ofrecen una funcionalidad mejorada a través de
          propiedades de producto optimizadas de forma innovadora, o simplemente, una tecnología más
          inteligente. <br /> <br />
          Sto Corp. lanza el revestimiento exterior StoColor® Dryonic® con una superficie
          microtexturada única que permite que la superficie se seque en un tiempo récord, privando
          a las algas y hongos de los nutrientes que necesitan para crecer.
          <br /> <br /> Sto Corp. lanza The ART of Restore ™. Un enfoque completo para la
          restauración que aborda la estética, la reparación y la transformación de edificios.
        </p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
});

export default Timeline;
