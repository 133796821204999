import React from 'react';
import styled from 'styled-components';

// components
import Layout from '../components/layout';
import Seo from '../components/seo';
import MiniHeader from '../components/mini-header';
import Timeline from '../components/timeline';

// img
import headerBackground from '../images/history/history_header.jpg';

// styled-components
const TimelineArticle = styled.article`
  padding: 45px 10px;
`;

const TimelineTitle = styled.div`
  text-align: left;
  padding: 10px 0;

  @media only screen and (min-width: 1170px) {
    text-align: center;
  }
`;

const TimelineTitleSpan = styled.span`
  color: #999999;
  font-size: 18px;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 2px solid #999999;
`;

const History = React.memo(() => (
  <Layout>
    <Seo title="History" />
    <MiniHeader text="Historia" background={headerBackground} />
    <TimelineArticle>
      <TimelineTitle>
        <TimelineTitleSpan>Nuestra Línea de Tiempo</TimelineTitleSpan>
      </TimelineTitle>
      <Timeline />
    </TimelineArticle>
  </Layout>
));

export default History;
